import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import {
  InlineTextButton,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LandingFooter,
  PaginationLinks,
  WantedTable,
} from '../../components';
import SelectSingleFilterPopup from '../../components/SelectSingleFilter/SelectSingleFilterPopup';
import { TopbarContainer } from '..';
import config from '../../config';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { boundsToLocation, userLocation } from '../../util/maps';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  manualLoadData,
  queryPreviousListings,
  messageProvider,
  closeMessageModal,
  claimItem,
  closeClaimModal,
} from './WantedPage.duck';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import defaultLocations from '../../default-location-searches';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { requestCreateListingDraft } from '../EditListingPage/EditListingPage.duck';
import { SearchForm } from '../../forms';
import { LISTING_TYPE, DEFAULT_TITLE } from '../../constants';
import { createSlug } from '../../util/urlHelpers';

import css from './WantedPage.module.css';

const sortTypes = {
  NAME: 'title',
  DISTANCE: 'origin',
  EARLIEST_TIME: 'publicData.startTime',
};
const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
const seattleCenter = boundsToLocation(
  defaultLocations.find(l => l.id === 'default-seattle').predictionPlace.bounds
);
const { LatLng } = sdkTypes;

const WantedPageComponent = props => {
  const {
    isAuthenticated,
    currentUser,
    history,
    location,
    scrollingDisabled,
    onManageDisableScrolling,
    intl,
    // wanted listings
    onFetchListings,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    // user previous listings for claim modal
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    onFetchPreviousListings,
    // message
    onMessageProvider,
    onCloseMessageModal,
    messageProviderInProgress,
    messageSent,
    messageProviderError,
    // claim item
    onClaimItem,
    onCloseClaimModal,
    claimInProgress,
    claimSent,
    claimError,
    onCreateNewListing,
  } = props;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'WantedPage.schemaTitle' }, { siteTitle });

  const [userLatLng, setUserLatLng] = useState(null);
  const seattleLatLng = useMemo(
    () => (seattleCenter ? new LatLng(seattleCenter.lat, seattleCenter.lng) : null),
    []
  );

  const hasPaginationInfo = useMemo(() => !!pagination && pagination.totalItems !== null, [
    pagination,
  ]);
  const listingsAreLoaded = useMemo(() => !queryInProgress && hasPaginationInfo, [
    queryInProgress,
    hasPaginationInfo,
  ]);

  const loadingResults = useMemo(
    () => (
      <h2>
        <FormattedMessage id="WantedPage.loadingListings" />
      </h2>
    ),
    []
  );

  const queryError = useMemo(
    () => (
      <h2 className={css.error}>
        <FormattedMessage id="WantedPage.queryError" />
      </h2>
    ),
    []
  );

  const noResults = useMemo(
    () =>
      listingsAreLoaded && listings.length === 0 ? (
        <h2>
          <FormattedMessage id="WantedPage.noResults" />
        </h2>
      ) : null,
    [listingsAreLoaded, listings.length]
  );

  const { page, keywords, sort, category, city } = parse(location.search);
  const initialSearchFormValues = useMemo(() => ({ keywords }), [keywords]);
  const paginationLinks = useMemo(
    () =>
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="WantedPage"
          pageSearchParams={{ keywords, sort, category, city, page: page ? page : 1 }}
          pagination={pagination}
        />
      ) : null,
    [listingsAreLoaded, pagination, keywords, page, sort, category, city]
  );

  const onSearchSubmit = useCallback(
    values => {
      history.push(
        createResourceLocatorString(
          'WantedPage',
          routeConfiguration(),
          {},
          { page, sort, category, city, keywords: values.keywords }
        )
      );
    },
    [category, city, history, page, sort]
  );

  const onClearSearch = useCallback(() => {
    history.push(
      createResourceLocatorString('WantedPage', routeConfiguration(), {}, { sort, category, city })
    );
  }, [category, city, history, sort]);

  const setSort = useCallback(
    sort => {
      history.push(
        createResourceLocatorString(
          'WantedPage',
          routeConfiguration(),
          {},
          { keywords, sort: sort, category, city }
        )
      );
    },
    [history, keywords, category, city]
  );

  const onChangeCategory = useCallback(
    param => {
      const { category } = param;
      history.push(
        createResourceLocatorString(
          'WantedPage',
          routeConfiguration(),
          {},
          { keywords, sort, category, city }
        )
      );
    },
    [history, keywords, sort, city]
  );

  const onChangeCity = useCallback(
    param => {
      const { city } = param;
      history.push(
        createResourceLocatorString(
          'WantedPage',
          routeConfiguration(),
          {},
          { keywords, sort, category, city: city }
        )
      );
    },
    [history, keywords, sort, category]
  );

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const location = await userLocation();
        setUserLatLng(location);
      } catch {}
    };
    getUserLocation();
  }, []);

  useEffect(() => {
    onFetchListings({
      page,
      perPage: 5,
      keywords,
      sort,
      category,
      userLatLng: userLatLng ? userLatLng : seattleLatLng,
    });
  }, [onFetchListings, page, keywords, sort, category, userLatLng, seattleLatLng]);

  const createWantedLink = (
    <span
      onClick={() =>
        onCreateNewListing({
          title: DEFAULT_TITLE,
          publicData: {
            listingType: LISTING_TYPE.WANTED_POST,
          },
        }).then(res => {
          const { uuid } = res.data.data.id;
          const slug = createSlug(res.data.data.attributes.title);
          history.push(`/l/${slug}/${uuid}/draft/description`, {
            claimedItemId: currentUser?.id?.uuid,
          });
        })
      }
    >
      <span className={css.button}>
        <FormattedMessage id="WantedPage.createWantedPost" />
      </span>
    </span>
  );

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentSearchParams={{ page, keywords, sort, category, city }}
            currentPage="WantedPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.container}>
            <div className={css.titleAndDescription}>
              <div className={css.titleContainer}>
                <h1 className={css.title}>{intl.formatMessage({ id: 'WantedPage.title' })}</h1>
              </div>
              <div className={css.searchAndFilters}>
                <div className={css.selects}>
                  <SearchForm
                    className={css.searchLink}
                    onSubmit={onSearchSubmit}
                    initialValues={initialSearchFormValues}
                    placeHolder={intl.formatMessage({ id: 'WantedPage.searchPlaceholder' })}
                    onClear={onClearSearch}
                    showLocation={false}
                  />
                  <SelectSingleFilterPopup
                    queryParamNames={['category']}
                    label={intl.formatMessage({ id: 'WantedPage.categories' })}
                    showDownArrow
                    labelStyle={css.categoryLabel}
                    arrowStyle={css.dropdownArrow}
                    onSelect={onChangeCategory}
                    options={categoryOptions}
                    initialValues={{ category: category }}
                  />
                  <SelectSingleFilterPopup
                    queryParamNames={['city']}
                    label={intl.formatMessage({ id: 'WantedPage.cities' })}
                    showDownArrow
                    labelStyle={css.dropdownLabel}
                    arrowStyle={css.dropdownArrow}
                    onSelect={onChangeCity}
                    options={[{ key: 'seattle', label: 'Seattle' }]}
                    initialValues={{ city: city }}
                  />
                </div>
                {createWantedLink}
              </div>
            </div>
            <div className={css.sortContainer}>
              <span className={css.description}>
                {intl.formatMessage({ id: 'WantedPage.description' })}
              </span>
              <div className={css.sortOptions}>
                <FormattedMessage id="WantedPage.sortTitle" />
                <InlineTextButton
                  className={classNames(css.sort, css.sortFirst, {
                    [css.sortSelected]: sort === sortTypes.NAME,
                  })}
                  onClick={() => {
                    setSort(sortTypes.NAME);
                  }}
                >
                  <FormattedMessage id="WantedPage.sortName" />
                </InlineTextButton>
                <InlineTextButton
                  className={classNames(css.sort, {
                    [css.sortSelected]: sort === sortTypes.DISTANCE,
                    [css.sortDisabled]: !userLatLng,
                  })}
                  onClick={() => setSort(sortTypes.DISTANCE)}
                >
                  <FormattedMessage id="WantedPage.sortDistance" />
                </InlineTextButton>
                <InlineTextButton
                  className={classNames(css.sort, {
                    [css.sortSelected]: sort === sortTypes.EARLIEST_TIME,
                  })}
                  onClick={() => setSort(sortTypes.EARLIEST_TIME)}
                >
                  <FormattedMessage id="WantedPage.sortEarliestTime" />
                </InlineTextButton>
              </div>
            </div>
            {queryListingsError ? queryError : null}
            {queryInProgress ? (
              loadingResults
            ) : listings.length > 0 ? (
              <WantedTable
                history={history}
                // user
                currentUser={currentUser}
                listings={listings}
                // previous listings
                onFetchPreviousListings={onFetchPreviousListings}
                previousListings={previousListings}
                previousListingsPagination={previousListingsPagination}
                queryPreviousListingsInProgress={queryPreviousListingsInProgress}
                queryPreviousListingsError={queryPreviousListingsError}
                // message
                onMessageProvider={onMessageProvider}
                onCloseMessageModal={onCloseMessageModal}
                messageProviderInProgress={messageProviderInProgress}
                messageSent={messageSent}
                messageProviderError={messageProviderError}
                // claim items
                onClaimItem={onClaimItem}
                onCloseClaimModal={onCloseClaimModal}
                claimInProgress={claimInProgress}
                claimSent={claimSent}
                claimError={claimError}
                onCreateNewListing={onCreateNewListing}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            ) : (
              noResults
            )}
            {paginationLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <LandingFooter isAuthenticated={isAuthenticated} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

WantedPageComponent.defaultProps = {
  isAuthenticated: false,
  listings: [],
  pagination: null,
  queryListingsError: null,
  previousListingsPagination: null,
  queryPreviousListingsError: null,
  claimError: null,
};

WantedPageComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  onFetchListings: func.isRequired,
  listings: arrayOf(object).isRequired,
  queryInProgress: bool.isRequired,
  onFetchPreviousListings: func.isRequired,
  previousListings: arrayOf(object).isRequired,
  queryPreviousListingsInProgress: bool.isRequired,
  onClaimItem: func.isRequired,
  onCloseClaimModal: func.isRequired,
  claimInProgress: bool.isRequired,
  claimSent: bool.isRequired,
  onCreateNewListing: func.isRequired,
  scrollingDisabled: bool.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    messageProviderInProgress,
    messageSent,
    messageProviderError,
    claimInProgress,
    claimSent,
    claimError,
  } = state.WantedPage;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
    // wanted listings
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    // user listings, for claim modal
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    scrollingDisabled: isScrollingDisabled(state),
    // message
    messageProviderInProgress,
    messageSent,
    messageProviderError,
    // claim item
    claimInProgress,
    claimSent,
    claimError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onFetchListings: params => dispatch(manualLoadData(params)),
  onFetchPreviousListings: page => dispatch(queryPreviousListings({ page })),
  onMessageProvider: (listingId, userId, text) =>
    dispatch(messageProvider(listingId, userId, text)),
  onCloseMessageModal: () => dispatch(closeMessageModal()),
  onClaimItem: (claimedItemId, listingId, userId, messageContent) =>
    dispatch(claimItem(claimedItemId, listingId, userId, messageContent)),
  onCloseClaimModal: () => dispatch(closeClaimModal()),
  onCreateNewListing: data => dispatch(requestCreateListingDraft(data)),
});

const WantedPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(WantedPageComponent);

export default WantedPage;
